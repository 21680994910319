<template>
    <v-menu top :close-on-click="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          <div class="d-flex flex-column justify-center align-center">
            <img v-if="$vuetify.breakpoint.smAndUp" height="30px" width="30px" style="cursor: pointer;" :src="currentLanguage === 'en' ? languages[0].img: languages[1].img" />
            {{ currentLanguage === 'en' ? 'English' : 'Deutsch' }}
          </div>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="d-flex justify-center" v-for="(language, index) in languages" :key="index">
          <img @click="changeLanguage(language.code)" height="30px" width="30px" style="cursor: pointer;" :src="language.img" />
        </v-list-item>
      </v-list>
    </v-menu>
</template>


<script>
export default {
    name: 'Languagedropdown',
    props: {
      data: Array,
    },
    data: () => ({
      languages: [
        { name: 'English', code: 'en', img: require("../assets/united-states.png") },
        { name: 'German', code: 'de', img: require("../assets/germany.png") }
      ]
    }),
    computed: {
      currentLanguage() {
        return this.$i18n.locale
      }
    },
    methods: {
      changeLanguage(language) {
        this.$i18n.locale = language;
      }
    }
}
</script>

